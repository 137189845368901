<template>
  <v-layout column>
    <complaint-info
      :loading="isLoading"
      :loading-error="loadingError"
      @refresh="refreshData"
    />

    <complaint-history
      :loading="isLoading"
      :loading-error="loadingError"
    />

    <complaint-files
      :loading="isLoading"
      :loading-error="loadingError"
    />
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import ComplaintInfo from '../_components/ComplaintInfo.vue';
import ComplaintHistory from '../_components/ComplaintHistory.vue';
import ComplaintFiles from '../_components/ComplaintFiles.vue';

const dataLoadingOptions = {
  getterName: 'getComplaint', searchable: false, paged: false, parameterize: true,
};

export default {
  name: 'IrRefund',

  components: {
    ComplaintInfo,
    ComplaintHistory,
    ComplaintFiles,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  created() {
    this.getAccounts({ activeOnly: false });
  },

  methods: {
    ...mapActions('refunds', ['getComplaint']),

    ...mapActions('accounts', ['getAccounts']),

    refreshData() {
      this.allowGettingData = true;
    },
  },
};
</script>
