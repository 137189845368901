<template>
  <vbt-dialog
    v-model="dialog"
    :disable-submit-btn="disableSubmit"
    title="Review Complaint"
    submit-btn-name="Review"
    @submit="reviewComplaint"
    @close="resetForm"
  >
    <template #activator="{ on }">
      <v-btn
        :loading="loading"
        x-small
        color="success"
        v-on="on"
      >
        Review
      </v-btn>
    </template>

    <v-checkbox
      v-model="providersFault"
      label="Providers Fault"
    />

    <v-textarea
      v-model.trim="description"
      label="Description"
      no-resize
      outlined
    />
  </vbt-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { RefundsApiService } from '../_services/refunds.api.service';

export default {
  name: 'ComplaintReview',

  props: {
    loading: Boolean,
  },

  data() {
    return {
      dialog: false,

      providersFault: false,
      description: '',
    };
  },

  computed: {
    ...mapState('refunds', {
      complaintId: ({ complaint: { id } }) => id,
    }),

    disableSubmit() {
      return !this.description;
    },
  },

  methods: {
    reviewComplaint() {
      this.$VBlackerTheme.alert.warning({ text: 'Review complaint?' }, () => this.wrapToLoadingFn({
        req: RefundsApiService.reviewComplaint.bind({}, {
          id: this.complaintId,
          data: {
            providersFault: this.providersFault,
            description: this.description,
          },
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.dialog = false;
          this.$emit('success');
          this.$VBlackerTheme.notification.success('Successfully reviewed');
        },
      }));
    },

    resetForm() {
      this.providersFault = false;
      this.description = '';
    },

    wrapToLoadingFn,
  },
};
</script>
