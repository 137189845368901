<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    title="Information"
  >
    <template #toolbar>
      <complaint-review
        :loading="loading"
        @success="$emit('refresh')"
      />
    </template>

    <v-layout>
      <v-flex sm4>
        <strong>
          Order ID:
        </strong>

        <router-link
          :to="{
            name: 'order',
            params: { id: complaint.orderId },
          }"
          class="blue--text darken-1"
          target="_blank"
        >
          {{ complaint.orderId }}
        </router-link>
      </v-flex>

      <v-flex sm4>
        <strong>
          Order Item ID:
        </strong>

        {{ complaint.orderId }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Status:
        </strong>

        {{ complaintStatusesByTypeId[complaint.status] }}
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4>
        <strong>
          Created Date:
        </strong>

        {{ complaint.created | formatDate('local', '-') }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Customer:
        </strong>

        {{ accountNameById[complaint.printCustomerId] }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Provider:
        </strong>

        {{ accountNameById[complaint.printProviderId] }}
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4>
        <strong>
          Reason:
        </strong>

        {{ complaint.reason }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Compensate Production:
        </strong>

        <complaint-state-icon :state="Boolean(complaint.compensateProduction)" />
      </v-flex>

      <v-flex sm4>
        <strong>
          Compensate Shipping:
        </strong>

        <complaint-state-icon :state="Boolean(complaint.compansateShipping)" />
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4>
        <strong>
          Print Cost:
        </strong>

        {{ complaint.printCost }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Ship Cost:
        </strong>

        {{ complaint.shipCost }}
      </v-flex>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ComplaintStateIcon from './ComplaintStateIcon.vue';
import ComplaintReview from './ComplaintReview.vue';

export default {
  name: 'ComplaintInfo',

  components: {
    ComplaintStateIcon,
    ComplaintReview,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState('refunds', ['complaint']),

    ...mapGetters('accounts', ['accountNameById']),
    ...mapGetters('enums', ['complaintStatusesByTypeId']),
  },
};
</script>
