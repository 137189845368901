<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    title="Files"
  >
    <vbt-table
      :headers="headers"
      :items="complaintFiles"
      :page-size="5"
      show-paginator
    >
      <template #processed="{ item: { processed } }">
        {{ processed | formatDate('local', '-') }}
      </template>

      <template #url="{ item: { url } }">
        <a
          :href="url"
          class="blue--text darken-1"
          target="_blank"
        >
          {{ url }}
        </a>
      </template>
    </vbt-table>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

const headers = Object.freeze([
  { text: 'URL', value: 'url' },
  { text: 'Hash', value: 'hash' },
]);

export default {
  name: 'ComplaintFiles',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState('refunds', {
      complaintFiles: ({ complaint: { files: items } }) => items || [],
    }),

    noContent() {
      return this.complaintFiles.length < 1;
    },
  },
};
</script>
